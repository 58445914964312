import { Component, Vue } from 'vue-property-decorator';
import * as API_activityType from '@/api/activityType';
import {
  appendActivitiesToZone,
  listActivityZoneDetails,
  removeAllZoneActivities,
  setZoneActivitiesSort,
  setZoneActivityDisplayable,
  listNews,
  deleteNews,
  changeNewsStatus,
} from "@/api/$platform";
import { param } from 'jquery';

export default @Component({})
class ActivityZoneDetailPage extends Vue {
  dataset = { page_no: 1 };
  selectedData = [];
  searchContent = '';
  dialogParams={
    type: '',
    status: ''
  };
  displayActivityPicker = false;
  activityTypeList = [];
  selectedLists = [];
  parentId ='';
  get hasSelected() {
    return this.selectedData.length > 0;
  }

  mounted() {
    this.load();
    API_activityType.activeTypeGet(0, {}).then(res => {
      this.activityTypeList = [];
      res.forEach((item1, index1) => {
        var jsonParent = {};
        jsonParent.value = item1.category_id;
        jsonParent.label = item1.name;
        var children = [];
        if (item1.children) {
          item1.children.forEach((item2, index2) => {
            var jsonChild = {};
            jsonChild.value = item2.category_id;
            jsonChild.label = item2.name;
            children.push(jsonChild);
          });
        }
        jsonParent.children = children;
        this.activityTypeList.push(jsonParent);
      });
    })
  }

  refresh() {
    this.load();
  }

  currentPageChange(index) {
    this.dataset.page_no = index;
    this.load();
  }

  load () {
    this.parentId = this.$route.params.id;
    if (this.parentId === '7') {
      listNews({
        page_no: this.dataset.page_no,
        page_size: 20,
        key: this.searchContent || undefined,
        ...this.dialogParams
      }).then(resp => {
        resp.data = resp.data.map(item => {
          item.goods_image = item.small;
          item.goods_price = item.price;
          return item;
        })
        this.dataset = resp;
      })
    } else {
      listActivityZoneDetails({
        id: this.$route.params.id,
        name: this.searchContent || undefined,
        page_no: this.dataset.page_no,
        page_size: 20,
      }).then(resp => {
        resp.data = resp.data.sort((a, b) => {
          return a.sort - b.sort;
        });
        this.selectedLists = resp.data.map(item => {
          item.goods_image = item.small;
          item.goods_price = item.price;
          return item;
        });
        this.dataset = resp;
      });
    }
  }

  handleSelectionChange(e) {
    this.selectedData = e;
  }

  setActivityDisplayable(row) {
    setZoneActivityDisplayable({
      id: row.id,
      showable: row.showable ? 1 : 0
    }).then(resp => {
      if (row.showable) {
        this.$message.success(`活动已显示`);
      } else {
        this.$message.warning(`活动已隐藏`);
      }
    });
  }

  removeZoneActivity(row) {
    this.removeSelectedActivities([row.id]);
  }

  removeSelectedActivities(ids = this.selectedData.map(d => d.id).join()) {
    removeAllZoneActivities({ ids }).then(resp => {
      this.$message.success('删除成功');
      this.load();
    });
  }

  moveDown(row) {
    setZoneActivitiesSort({
      id: row.id,
      type: 'down',
      sort: row.sort,
      idx_category_id: row.idx_category_id,
    }).then(resp => {
      // console.log(resp);
      this.$message.success('下移成功');
      this.load();
    });
  }

  moveUp(row) {
    setZoneActivitiesSort({
      id: row.id,
      type: 'up',
      sort: row.sort,
      idx_category_id: row.idx_category_id,
    }).then(resp => {
      // console.log(resp);
      this.$message.success('上移成功');
      this.load();
    });
  }

  appendActivities(items) {
    appendActivitiesToZone({
      id: this.$route.params.id,
      indexCategoryGoods: items.map(item => {
        return {
          "cat_first_name": item.category_first || item.cat_first_name,
          "cat_second_name": item.category_second || item.cat_second_name,
          "category_id": item.category_id,
          "goods_name": item.goods_name,
          "goods_sn": item.goods_sn,
          "idx_category_id": this.$route.params.id,
          "remarks": item.remark || item.remarks,
          "shop_id": item.seller_id,
          "shop_name": item.seller_name
        }
      })
    }).then(resp => {
      this.dataset.page_no = 1;
      this.load();
    });
  }
  // 添加
  toZoneDetail () {
    this.$router.push({
      name: "资讯详情"
    });
    // this.$router.push({
    //   path: `/customer/customer-detail`,
    // });
  }
  // 编辑
  handleEdit (row) {
    this.$router.push({
      name: "资讯详情",
      params: {
        id: row.consult_id
      }
    });
  }
  // 下架
  handleUnder (row) {
    const id = row.consult_id;
    const status = 0;
    changeNewsStatus(id, status).then(resp => {
      // console.log(resp);
      this.$message.success('下架成功');
      this.load();
    });
  }
  // 上架
  handleUp (row) {
    const id = row.consult_id;
    const status = 1;
    changeNewsStatus(id, status).then(resp => {
      this.$message.success('上架成功');
      this.load();
    });
  }
  handleRemove (row) {
    deleteNews(row.consult_id).then(resp => {
      this.$message.success('删除成功');
      this.load();
    });
  }
}
