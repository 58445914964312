<template>
  <div class="page-activity-zone-detail">
    <!-- 资讯专区 -->
    <el-card v-if="parentId == '7'">
      <div slot="header" class="row align-items-center">
        <div class="col-auto">
          <el-input placeholder="请输入资讯名称 / 资讯编号" clearable style="width: 300px" v-model="searchContent">
            <el-button slot="append" @click="load">筛选</el-button>
          </el-input>
        </div>
        <div class="col-auto">
          <span>资讯类型：</span>
          <el-select v-model="dialogParams.type" placeholder="请选择" clearable>
            <el-option label="全部" value></el-option>
            <el-option label="图文" :value="0"></el-option>
            <el-option label="视频" :value="1"></el-option>
          </el-select>
        </div>
        <div class="col-auto">
          <span>状态：</span>
          <el-select v-model="dialogParams.status" placeholder="请选择" clearable>
            <el-option label="全部" value></el-option>
            <el-option label="已上架" :value="1"></el-option>
            <el-option label="已下架" :value="0"></el-option>
          </el-select>
        </div>

        <div class="col"></div>
        <div class="col-auto">
          <el-button type="primary" @click="toZoneDetail">添加资讯</el-button>
        </div>
      </div>

      <el-table :data="dataset.data" border style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column type="selection" align="center" width="55">
        </el-table-column>
        <el-table-column prop="sort" label="序号" width="80">
          <template slot-scope="scope">
            <div class="row no-gutters align-items-center data-sort">
              <div class="col">{{ scope.$index + 1 }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column key="information_number" prop="consult_id" label="资讯编号" width="150" />
        <el-table-column prop="title" label="资讯名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="资讯类型" width="80">
          <template slot-scope="scope">
            {{ scope.row.type == 0 ? '图文' : '视频' }}
          </template>
        </el-table-column>

        <el-table-column key="create_time" label="发布时间" width="160" prop="create_time" :formatter="MixinUnixToDate" />
        <el-table-column label="上架状态" width="80">
          <template slot-scope="scope">
            {{ scope.row.status ? '已上架' : '已下架' }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="180">
          <template slot-scope="scope">

            <el-button type="text" @click="handleUnder(scope.row)" v-if="scope.row.status == 1">下架</el-button>
            <el-button type="text" @click="handleUp(scope.row)" v-if="scope.row.status == 0">上架</el-button>
            <el-button type="text" @click="handleEdit(scope.row)" v-if="scope.row.status == 0">编辑</el-button>
            <el-button type="text" @click="handleRemove(scope.row)" v-if="scope.row.status == 0"
              style="color: #DB4F2E">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="row pt-3">
        <div class="col"></div>
        <div class="col-auto">
          <el-pagination background layout="prev, pager, next" :total="dataset.data_total" :page-size="20"
            :current-page="dataset.page_no" @current-change="currentPageChange">
          </el-pagination>
        </div>
      </div>
    </el-card>
    <!-- 活动专区 -->
    <el-card v-else>
      <div slot="header" class="row align-items-center">
        <div class="col-auto">
          <el-input placeholder="请输入活动名称 / 活动编码" style="width: 300px" v-model="searchContent">
            <el-button slot="append" @click="load">筛选</el-button>
          </el-input>
        </div>
        <div class="col"></div>
        <div class="col-auto">
          <transition name="el-zoom-in-center">
            <el-button type="danger" v-if="hasSelected" @click="removeSelectedActivities()">删除选中活动</el-button>
          </transition>
        </div>
        <div class="col-auto">
          <el-button type="primary" @click="displayActivityPicker = true">添加活动</el-button>
        </div>
      </div>

      <el-table :data="dataset.data" border style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column type="selection" align="center" width="55">
        </el-table-column>
        <el-table-column prop="sort" label="序号" width="80">
          <template slot-scope="scope">
            <div class="row no-gutters align-items-center data-sort">
              <div class="col">{{ scope.$index + 1 }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="goods_sn" label="活动编号">
        </el-table-column>
        <el-table-column prop="goods_name" label="活动名称">
        </el-table-column>
        <el-table-column label="所属分类" width="120">
          <template slot-scope="scope">
            {{ scope.row.cat_first_name }}{{ scope.row.cat_second_name ? `-${scope.row.cat_second_name}` : '' }}
          </template>
        </el-table-column>
        <el-table-column prop="sort" label="是否显示" width="120">
          <template slot-scope="scope">
            <el-switch :active-value="1" :inactive-value="0" v-model="scope.row.showable"
              @change="setActivityDisplayable(scope.row)" />
          </template>
        </el-table-column>
        <el-table-column label="排序">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="180">
          <template slot-scope="scope">
            <el-button type="text" @click="removeZoneActivity(scope.row)" style="color: #DB4F2E">删除</el-button>
            <el-button type="text" @click="moveDown(scope.row)"
              v-if="scope.$index < dataset.data.length - 1">下移</el-button>
            <el-button type="text" @click="moveUp(scope.row)" v-if="scope.$index > 0">上移</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="row pt-3">
        <div class="col"></div>
        <div class="col-auto">
          <el-pagination background layout="prev, pager, next" :total="dataset.data_total" :page-size="20"
            :current-page="dataset.page_no" @current-change="currentPageChange">
          </el-pagination>
        </div>
      </div>
    </el-card>
    <EnGoodsPicker :show="displayActivityPicker" :api="'/admin/systems/indexCategory/search_category'"
      :multipleApi="'/admin/systems/indexCategory/serach_sn'" :activityTypeList="activityTypeList"
      :selectedLists="selectedLists" @close="displayActivityPicker = false;" @confirm="appendActivities" />
  </div>
</template>

<script lang="js">
import ActivityZoneDetailPage from './activity-zone-detail';

export default ActivityZoneDetailPage;
</script>

<style lang="scss">
@import "./activity-zone-detail";
</style>
